import Contact from 'components/contacts/Contact';
import ContactsListContainer from 'components/contacts/ContactsListContainer.jsx';
import ContactsMenuContainer from 'components/contacts/ContactsMenuContainer';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Segment, Sidebar } from 'semantic-ui-react'

export default class Contacts extends React.Component {
  render () {
    return (
      <div>
        <Switch>
          <Route path={Routes.contact_path({ id: ":id", _options: true})} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <ContactsMenuContainer/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <Contact contactId={props.match.params.id}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />

          <Route path={Routes.contacts_path()} component={ContactsListContainer}/>
        </Switch>
      </div>
    )
  }
}

