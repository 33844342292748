import ContactForm from "../components/contacts/ContactForm";
import OrganisationForm from "../components/organisations/OrganisationForm";

const relationshipSchema = function (state, props, relationship) {

  let isOrganisation = relationship.relationshipType === "organisation"
  let uiSchema = {
    "relationshipType": {
      "ui:group": "line",
      "ui:field": "SelectorField",
      "ui:options": {
        "entityName": "relationship",
        "width": 3,
        "selectableOptions": ['standardparent', 'stepparent', 'gp', 'partner', 'organisation', 'other'],
      }
    },
    "contactId": {
      "ui:group": "line",
      "ui:visible": !isOrganisation,
      "ui:field": "SelectorField",
      "ui:options": {
        "width": 4,
        "entityName": "contact",
        "addable": true,
        "editable": true,
        "EntityForm": ContactForm,
        "displayAttribute": (contact) => `${contact.firstName} ${contact.lastName}`,
        "fetchEntityAfterSelect": true
      }
    },
    "organisationId": {
      "ui:group": "line",
      "ui:visible": isOrganisation,
      "ui:field": "SelectorField",
      "ui:options": {
        "width": 4,
        "entityName": "organisation",
        "addable": true,
        "editable": true,
        "EntityForm": OrganisationForm,
        "displayAttribute": (organisation) => `${organisation.name}`,
        "fetchEntityAfterSelect": true
      }
    }
  }

  return uiSchema
}

export default relationshipSchema;
