import { attr, fk } from 'redux-orm'
import Base from './Base'

class Relationship extends Base {
  static get fields() {
    return {
      id: attr(),
      deleted: attr(),
      relationshipType: attr(),
      dossierId: fk({to: 'dossier', as: 'dossier', relatedName: 'relationships'}),
      contactId: fk({to: 'contact', as: 'contact', relatedName: 'relationships'}),
      organisationId: fk({to: 'organisation', as: 'organisation', relatedName: 'relationships'}),
    }
  }
}

Relationship.modelName = 'relationship';

export default Relationship;
