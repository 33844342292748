import React from 'react'
import OrganisationsListContainer from "../organisations/OrganisationsListContainer.jsx";
import PersonalContactsListContainer from "./PersonalContactsListContainer.jsx";

export default class ContactsListContainer extends React.Component {
  render() {
    return (
      <div>
        <PersonalContactsListContainer/>
        <OrganisationsListContainer/>
      </div>
    )
  }
}
