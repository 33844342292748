import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'semantic-ui-react'

import EntitiesList from 'components/entities/EntitiesList';
import ContactsListItem from 'components/contacts/ContactsListItem'
import ContactForm from 'components/contacts/ContactForm'

import { getContacts } from 'selectors/contacts'

import {  createEntityWithDefaults } from 'actions/entities';

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getContacts(state),
    entityName: 'contact',
    item: ContactsListItem,
    form: ContactForm,
    listComponent: Card.Group,
    loading: state.meta.contact?.loading,
    error: state.meta.contact?.error
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createEntityWithDefaults: () => {
      let contactId = dispatch(createEntityWithDefaults('contact'))
      let contactSliceId = dispatch(createEntityWithDefaults('contactSlice', { contactId: contactId }))
      return contactId
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList)
