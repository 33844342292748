import dotProp from "dot-prop";
import { flattenSchema } from 'flatten_entity'

import { createSelector } from 'reselect'

export const getUiSchema = (state,props) => {
  return props.uiSchema
}

export const getNestedUiSchema = (state,props) => {
  let nestedSchema = props.uiSchema["ui:schema"]
  if (typeof nestedSchema === "function") {
    let entityId = dotProp.get(state, `${props.model || ''}.${props.name}`)
    let entity = dotProp.get(state, `${props.model || ''}.${entityId}`)
    nestedSchema = nestedSchema(entity)
  }
  return nestedSchema
}

export const getUiOptions = (state, props) => {
  return getUiSchema(state,props)["ui:options"]
}

export const getEntityNameFromOptions = (state,props) => {
  return getUiOptions(state,props).entityName
}

export const getEntityName = (state,props) => {
  return props.resourceName || props.entityName
}

export const getResources = (state) => { return state.resources.list }

export const getResource = (entityName) => {
  return createSelector([getResources] ,(resources) => {
    return resources && resources.find((resource) => {
      return ( resource.attributes.title === entityName )
    })
  })
}

export const getSchema = (entityName) => {
  return createSelector([getResource(entityName)], (resource) => {
    return (resource && flattenSchema(resource.attributes.schema)) || {}
  })
}
